import React from 'react';
import SignUpPresenter from './SignUpPresenter';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { SignUp } from './SignUpQueries';

class SignUpContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            department: ""
        };
    }

    //Public SignUp Mutation
    signUpMutation;

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });

    };

    onClick = () => {
        const { email } = this.state;
        const isValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        if (isValid) {
            this.signUpMutation();
        } else {
            toast.error("Please write a valid email");
        }
    };

    render() {
        const { name, email, password, department } = this.state;
        const { history } = this.props;
        return (
            <Mutation
                mutation={SignUp}
                variables={{
                    name,
                    email,
                    password,
                    department
                }}
                onCompleted = { data => {
                    const { SignUp } = data;
                    if(SignUp.ok) {
                        toast.success("Sign Up Completed");
                        setTimeout(()=>{
                            history.push({
                                pathname: "/"
                            });
                        }, 2000);
                    }
                    else {
                        toast.error(SignUp.error);
                    }
                }}
            >
                {
                    (signUpMutation) => {
                        this.signUpMutation = signUpMutation;
                        return (
                            <SignUpPresenter 
                                name={name}
                                email={email}
                                password={password}
                                department={department}
                                onChange={this.onInputChange}
                                onClick={this.onClick}
                            />
                        )
                    }
                }
                
            </Mutation>
        )
    }
};

export default SignUpContainer;