import { gql } from 'apollo-boost';

export const UPDATE_TICKET_INFO = gql`
    mutation UpdateTicketInfo(
        $ticketInfoId: Int!
        $category: String
        $package: String
        $localAnPrice: Float
        $localFinalPrice: Float
        $localNetPrice: Float
        $InitialOpen: Int
        $additionalOpen: Int
        $reserved: Int
    ) {
        UpdateTicketInfo(
            ticketInfoId: $ticketInfoId
            category: $category
            package: $package
            localAnPrice: $localAnPrice
            localFinalPrice: $localFinalPrice
            localNetPrice: $localNetPrice
            InitialOpen: $InitialOpen
            additionalOpen: $additionalOpen
            reserved: $reserved
        ) {
            ok
            error
        }
    }
`;

export const READ_ONE_TICKETINFO = gql`
    query ReadOneTicketInfo(
        $ticketInfoId: Int!
    ) {
        ReadOneTicketInfo(
            ticketInfoId: $ticketInfoId
        )
        {
            ok
            error
            ticketInfo {
                id
                category
                package
                localAnPrice
                localFinalPrice
                localNetPrice
                InitialOpen
                additionalOpen
                reserved
                preSale
            }
        }
    }
`;

export const DELETE_TICKET_INFO = gql`
    mutation DeleteTicketInfo(
        $ticketInfoId: Int!
    ) {
        DeleteTicketInfo(
            ticketInfoId: $ticketInfoId
        ) {
            ok
            error
        }
    }
`;