import { gql } from 'apollo-boost';

export const READ_ONE_CONCERT = gql`
    query ReadOneConcert(
        $concertId: Int!
    ) {
        ReadOneConcert(
            concertId: $concertId
        )
        {
            ok
            error
            concert {
                nationName
                cityName
                bEP
                totalRevenue
                ticketCapacity
                salesEstimation
                totalSales
                generalSales
                promoSales
                venue
                currency
                exchangeRate
                exchangeRateDate
                showDate
                preSaleBenefit
                preSaleStartDate
                preSaleEndDate
                generalSaleStartDate
                generalSaleEndDate
                promotionLink
                promotionID
                comment
                reportStatus
                tour {
                    id
                    tourName
                    artistName
                    region
                }
            }
        }
    }
`;

export const READ_TICKETINFO = gql`
    query ReadTicketInfo(
        $concertId: Int!
    ) {
        ReadTicketInfo(
            concertId: $concertId
        )
        {
            ok
            error
            ticketInfo {
                id
                category
                package
                localAnPrice
                localFinalPrice
                localNetPrice
                usdAnPrice
                usdFinalPrice
                usdNetPrice
                totalCapacity
                InitialOpen
                additionalOpen
                reserved
                netCapacity
                remainder
                finalSales
                preSale
                genSales
                concert {
                    id
                }
            }
        }
    }
`;

export const READ_ONE_TICKETINFO = gql`
    query ReadOneTicketInfo(
        $ticketInfoId: Int!
    ) {
        ReadOneTicketInfo(
            ticketInfoId: $ticketInfoId
        )
        {
            ok
            error
            ticketInfo {
                id
                category
                package
                localAnPrice
                localFinalPrice
                localNetPrice
                usdAnPrice
                usdFinalPrice
                usdNetPrice
                totalCapacity
                InitialOpen
                additionalOpen
                reserved
                netCapacity
                remainder
                finalSales
                preSale
                genSales
            }
        }
    }
`;

export const DAILY_SALES_TICKET_LIST = gql`
    query DailySalesTicketList(
        $concertId: Int!
    ) {
        DailySalesTicketList(
            concertId: $concertId
        )
        {
            ok
            error
            dailyTicketInfo {
                id
                category
                preSale
                finalSales
                genSales
                dailySalesTicket {
                    id
                    salesDate
                    sales
                }
            }
        }
    }
`;

export const TICKET_SALES_DATE = gql`
    query ReadTicketDate(
        $concertId: Int!
    ) {
        ReadTicketDate(
            concertId: $concertId
        )
        {
            ok
            error
            result {
                salesDate
            }
        }
    }
`;

export const DAILY_TOTAL_SALES = gql`
    query DailyTotalSales(
        $concertId: Int!
    ) {
        DailyTotalSales(
            concertId: $concertId
        )
        {
            ok
            error
            totalSales {
                salesDate
                sales
            }
        }
    }
`;

export const DAILY_PROMO_SALES = gql`
    query DailyPromoSales(
        $concertId: Int!
    ) {
        DailyPromoSales(
            concertId: $concertId
        )
        {
            ok
            error
            promoSales {
                salesDate
                sales
            }
        }
    }
`;