import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import ArrowForward from "@material-ui/icons/ArrowForward";

//moment
import moment from 'moment';

import readTourStyle from "assets/jss/material-dashboard-pro-react/views/readTourStyle";

class ReadTourPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notValue: "Not Registered"
    };
  }
  render() {
    const { 
      classes,
      readTourData: { ReadOneTour: { tour =null } = {} } ={},
      readConcertListData: { ReadConcertList: { concert =null } = {} } ={},
      editOnClick,
      addConcertOnClick,
      concertOnClick
    } = this.props;

    const valueCheck = (value) => {
      if(value.length ===0 ) {
        return this.state.notValue
      }
      else {
        return value
      }
    }

    const processButton = (status) => {
      if(status) {
        return (
          <Button
            disabled
            color="facebook"
            size="sm"
          >
            Done
          </Button>
        )  
      }
      else {
        return (
          <Button
            disabled
            color="success"
            size="sm"
          >
            In Process
          </Button>
        )  
      }
    };

    const conversionTimestamp = timestamp => {
      const castingNumber = Number(timestamp)
      return moment(castingNumber).utc().format('YYYY-MM-DD HH:mm')
    }

    return (
      <div>
        <GridContainer className={classes.head}>
          <h2>{tour&&tour.tourName}</h2>
          <Button 
            simple 
            color="linkedin" 
            className={classes.editButton}
            onClick={editOnClick}
          >
            Edit  
          </Button>
        </GridContainer>
        <GridContainer className={classes.head}>
          {tour&&processButton(tour.status)}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardBody>
                <Table>
                  <TableBody>
                      <TableRow>
                        <TableCell align="center">Show Type</TableCell>
                        <TableCell align="center">{tour&&tour.showType}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Region</TableCell>
                        <TableCell align="center">{tour&&tour.region}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Project Manager</TableCell>
                        <TableCell align="center">{tour&&tour.projectManager}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Tour Manager</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.tourManager)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Marketing Manager</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.MarketingManager)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Assistant Marketing Manager</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.AMManager)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">User Communication Manager</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.UCManager)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Designer</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.designer)}</TableCell>
                      </TableRow>    
                      <TableRow>
                        <TableCell align="center">Video Content Creator</TableCell>
                        <TableCell align="center">{tour&& valueCheck(tour.VCC)}</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.head}>
          <h2>Concert</h2>
          <Button 
            simple 
            color="rose" 
            className={classes.editButton}
            onClick={addConcertOnClick}
          >
            + Add
          </Button>
        </GridContainer>
        <GridContainer>
          {concert&&concert.map(concert=>(
            <GridItem xs={12} sm={12} md={4} key={concert.id}>
              <Card className={classes.textCenter}>
                <CardBody>
                  <h4 className={classes.cardTitle}>{concert.nationName}</h4>
                  <p className={classes.cardCategory}>{concert.cityName}</p>
                  <p className={classes.cardCategory}>{conversionTimestamp(concert.showDate)}</p>
                </CardBody>
                {processButton(concert.reportStatus)}
                <Button simple color="tumblr" key={concert.id} onClick={concertOnClick.bind(this, concert.id)}>
                  <ArrowForward />
                </Button>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    );
  }
}

ReadTourPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(readTourStyle)(ReadTourPresenter);
