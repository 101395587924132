import React from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { CreateTour } from './AddTourQueries';
import AddTourPresenter from './AddTourPresenter';

class AddTourContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // artistId: "",
            artistName: "",
            tourName: "",
            region: "",
            showType: "",
            // regionId: "",
            projectManager: "",
            tourManager: "",
            MarketingManager: "",
            AMManager: "",
            UCManager: "",
            designer: "",
            VCC: "",
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    onClick = () => {
        const { tourName, artistName, region, showType, projectManager, tourManager } = this.state;
        if( 
            tourName.length === 0
            || artistName.length === 0
            || region.length === 0
            || showType.length === 0
            || projectManager.length === 0
            || tourManager.length === 0
        ) {
            toast.error("Enter Required Fields");
        }
        else {
            this.createTourMutation();
        }
    }

    render() {
        const { history } = this.props;
        const { 
            // artistId, 
            artistName, 
            tourName, 
            region, 
            // regionId, 
            projectManager, 
            tourManager,
            MarketingManager,
            AMManager,
            UCManager,
            designer,
            showType,
            VCC
        } = this.state;
        return (
            <Mutation
                mutation={CreateTour}
                variables= {{
                    // artistId, 
                    artistName, 
                    tourName, 
                    region, 
                    // regionId, 
                    projectManager, 
                    tourManager,
                    MarketingManager,
                    AMManager,
                    UCManager,
                    designer,
                    showType,
                    VCC
                }}
                onCompleted = { data => {
                    const { CreateTour } = data;
                    if(CreateTour.ok) {
                        toast.success("Create Tour Completed");
                        setTimeout(()=>{
                            history.push({
                                pathname: "/admin/tourlist"
                            });
                        }, 2000);
                    }
                    else {
                        toast.error(CreateTour.error);
                    }
                }}
            >
                {
                    (createTourMutation) => {
                        this.createTourMutation = createTourMutation
                        return (
                            <AddTourPresenter
                            onChange={this.onInputChange}
                            onClick={this.onClick}
                            // artistId={artistId} 
                            artistName={artistName} 
                            tourName={tourName}
                            region={region}
                            // regionId={regionId} 
                            projectManager={projectManager}
                            tourManager={tourManager}
                            MarketingManager={MarketingManager}
                            AMManager={AMManager}
                            UCManager={UCManager}
                            designer={designer}
                            showType={showType}
                            VCC={VCC}
                        />
                        )
                    }
                }
            </Mutation>
        )
    }
};

export default AddTourContainer;