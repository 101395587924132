import { gql } from 'apollo-boost';

export const READ_ONE_SALES_TICKET = gql`
    query ReadOneSalesTicket(
        $salesTicketId: Int!
    ) {
        ReadOneSalesTicket(
            salesTicketId: $salesTicketId
        )
        {
            ok
            error
            salesTicket {
                id
                salesDate
                sales
                ticketInfo {
                    id
                    category
                }
            }
        }
    }
`;

export const UPDATE_ONE_SALES_TICKET = gql`
    mutation UpdateOneSalesTicket(
        $salesTicketId: Int!
        $sales: Int
    ) {
        UpdateOneSalesTicket(
            salesTicketId: $salesTicketId
            sales: $sales
        ) {
            ok
            error
        }
    }
`;