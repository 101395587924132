import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import editTourStyle from "assets/jss/material-dashboard-pro-react/views/editTourStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditTourPresenter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalStatus: false
    };
  }

  render() {
    const { 
      classes, 
      onChange,
      onEditClick,
      onCancelChange,
      onDeleteClick,
      // artistId, 
      artistName, 
      tourName, 
      region, 
      // regionId, 
      projectManager, 
      tourManager,
      MarketingManager,
      AMManager,
      UCManager,
      designer,
      showType,
      VCC,
      loading
    } = this.props;

    const { modalStatus } = this.state;

    const modalButton = (modalStatus) => {
        this.setState({
          modalStatus: !modalStatus
        })
    }

    return (
      <div>
        {loading ? "Loading" :
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      Tour Information
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridItem xs={12} sm={3}>
                        <GridContainer>
                            <Button 
                                color="warning" 
                                onClick={() => modalButton(modalStatus)} 
                                className="deleteModal"
                            >
                                Delete
                            </Button>
                            <Dialog
                                classes={{
                                    root: classes.center + " " + classes.modalRoot,
                                    paper: classes.modal
                                }}
                                open={modalStatus}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => modalButton(modalStatus)}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="notice-modal-slide-title"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                    <Button
                                      justIcon
                                      className={classes.modalCloseButton}
                                      key="close"
                                      aria-label="Close"
                                      color="transparent"
                                      onClick={() => modalButton(modalStatus)}
                                    >
                                        <Close className={classes.modalClose} />
                                    </Button>
                                    <h4 className={classes.modalTitle}>Delete Tour</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="small-modal-slide-description"
                                    className={
                                    classes.modalBody + " " + classes.modalSmallBody
                                    }
                                >
                                    <h3 className={classes.modalTitle}>Are you sure delete?</h3>
                                </DialogContent>
                                <DialogActions
                                    className={
                                    classes.modalFooter + " " + classes.modalFooterCenter
                                    }
                                >
                                    <Button
                                        onClick={onDeleteClick}
                                        color="danger"
                                        simple
                                        className={
                                            classes.modalSmallFooterFirstButton +
                                            " " +
                                            classes.modalSmallFooterSecondButton
                                        }
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => modalButton(modalStatus)}
                                        color="transparent"
                                        className={classes.modalSmallFooterFirstButton}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </GridContainer>
                    </GridItem>   
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Tour Name
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="tour-name"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: tourName,
                            name: "tourName",
                            onChange,
                            // disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Artist
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: artistName,
                            name: "artistName",
                            onChange,
                            // disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Show Type
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Choose Show Type (Required Fields *)
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={showType}
                            onChange={onChange}
                            inputProps={{
                              name: "showType",
                              id: "showType"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Choose Show Type
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="Concert"
                            >
                              Concert
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="Fan Meeting"
                            >
                              Fan Meeting
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="Other"
                            >
                              Other
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Region
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="region"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: region,
                            name: "region",
                            onChange,
                            // disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Project Manager
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          labelText="Required Fields *"
                          id="projectManager"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: projectManager,
                            name: "projectManager",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Tour Manager
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          labelText="Required Fields *"
                          id="tourManager"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: tourManager,
                            name: "tourManager",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Marketing Manager
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="MarketingManager"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: MarketingManager,
                            name: "MarketingManager",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Assistant Marketing Manager
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="AMManager"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: AMManager,
                            name: "AMManager",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          User Communication Manager
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="UCManager"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: UCManager,
                            name: "UCManager",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Designer
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="designer"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: designer,
                            name: "designer",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Video Content Creator
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="VCC"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: VCC,
                            name: "VCC",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.buttondiv}>
                          <Button className={classes.button} round color="rose" onClick={onEditClick}>
                            Edit
                          </Button>
                          <Button className={classes.button} round color="tumblr" onClick={onCancelChange}>
                            Cancel
                          </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        }
      </div>
    );
  }
}

EditTourPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(editTourStyle)(EditTourPresenter);
