import React from 'react';
import SignInPresenter from './SignInPresenter';
import { LOG_USER_IN } from "../../sharedQueries.local";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { SignIn } from './SignInQueries';

class SignInContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    render() {
        const { email, password } = this.state;
        return (
            <Mutation mutation={LOG_USER_IN}>
                {logUserIn => (
                    <Mutation 
                        mutation={SignIn}
                        variables={{
                            email,
                            password
                        }}
                        onCompleted={data => {
                            const { SignIn } = data;
                            if(SignIn.ok) {
                                if(SignIn.token) {
                                    logUserIn({
                                        variables: {
                                            token: SignIn.token
                                        }
                                    });
                                }
                                toast.success("You're verified, loggin in now");
                            }
                            else {
                                toast.error(SignIn.error);
                            }
                        }}
                    >
                        {signIn => (
                            <SignInPresenter 
                                email={email}
                                password={password}
                                onChange={this.onInputChange}
                                onClick={signIn}
                            />
                        )}
                    </Mutation>
                )}
            </Mutation>
        )
    }
};

export default SignInContainer;