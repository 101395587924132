import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Link from '@material-ui/core/Link';

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";

// core components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// UserLogOut Mutation
import { Mutation } from "react-apollo";
import { LOG_USER_OUT } from "../../sharedQueries.local";


import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
  state = {
    openNotification: false,
    openProfile: false
  };
  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };
  render() {
    const { classes, rtlActive } = this.props;
    const { openNotification, openProfile } = this.state;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton +
      " " +
      classNames({
        [classes.searchRTL]: rtlActive
      });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <Mutation mutation={LOG_USER_OUT}>
        {logUserOut => (
          <div className={wrapper}>
            {/* <CustomInput
              rtlActive={rtlActive}
              formControlProps={{
                className: classes.top + " " + classes.search
              }}
              inputProps={{
                placeholder: "Search",
                inputProps: {
                  "aria-label": "Search",
                  className: classes.searchInput
                }
              }}
            /> */}
            <Link
              color="inherit"
              href="/admin/search"
            >
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                className={searchButton}
              >
                <Search
                  className={classes.headerLinksSvg + " " + classes.searchIcon}
                />
              </Button>
            </Link>
            <div className={managerClasses}>
              <Button
                color="transparent"
                aria-label="Person"
                justIcon
                aria-owns={openNotification ? "profile-menu-list" : null}
                aria-haspopup="true"
                onClick={this.handleClickProfile}
                className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : ""
                }}
                buttonRef={node => {
                  this.anchorProfile = node;
                }}
              >
                <Person
                  className={
                    classes.headerLinksSvg +
                    " " +
                    (rtlActive
                      ? classes.links + " " + classes.linksRTL
                      : classes.links)
                  }
                />
                <Hidden mdUp implementation="css">
                  <span
                    onClick={this.handleClickProfile}
                    className={classes.linkText}
                  >
                    Profile
                  </span>
                </Hidden>
              </Button>
              <Popper
                open={openProfile}
                anchorEl={this.anchorProfile}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                  [classes.popperClose]: !openProfile,
                  [classes.popperResponsive]: true,
                  [classes.popperNav]: true
                })}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="profile-menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={this.handleCloseProfile}>
                        <MenuList role="menu">
                          <MenuItem
                            onClick={logUserOut}
                            className={dropdownItem}
                          >
                            Log out
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
