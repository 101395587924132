import { gql } from 'apollo-boost';

export const SignUp = gql`
    mutation SignUp(
        $name: String!
        $email: String!
        $password: String!
        $department: String!
    ) {
        SignUp(
            name: $name
            email: $email
            password: $password
            department: $department
        ) {
            ok
            error
            token
        }
    }
`;