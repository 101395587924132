import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import editDailyTicketStyle from "assets/jss/material-dashboard-pro-react/views/editDailyTicketStyle";

class EditDailySalesPresenter extends React.Component {

  render() {
    const { 
      classes,
      onChange,
      onClick,
      onCancelClick,
      sales,
      dailySalesData: { ReadOneSalesTicket: { salesTicket = null } = {} } = {},
    } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" text>
                <CardText color="success">
                  <h4 className={classes.cardTitle}>{salesTicket&&salesTicket.ticketInfo.category} Ticket ({salesTicket&&salesTicket.salesDate})</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                    <GridContainer>
                        <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                            Sales ({salesTicket&&salesTicket.salesDate})
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9}>
                        <CustomInput
                            labelText="Required Fields *"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                value: sales,
                                name: "sales",
                                onChange,
                            }}
                        />
                        </GridItem>
                    </GridContainer>
                    <div className={classes.buttondiv}>
                          <Button className={classes.button} round color="success" onClick={onClick}>
                            Edit
                          </Button>
                          <Button className={classes.button} round color="tumblr" onClick={onCancelClick}>
                            Cancel
                          </Button>
                    </div>
                  </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EditDailySalesPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(editDailyTicketStyle)(EditDailySalesPresenter);
