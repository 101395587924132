import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { toast } from 'react-toastify';
import EditTicketPresenter from './EditTicketPresenter';
import { 
    READ_ONE_TICKETINFO, 
    UPDATE_TICKET_INFO,
    DELETE_TICKET_INFO
} from './EditTicketQueries';

class EditTicketContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            concertId: Number(props.location.state.concertId),
            ticketInfoId: Number(props.location.state.ticketInfoId),
            category: "",
            localAnPrice: "",
            localFinalPrice: "",
            localNetPrice: "",
            InitialOpen: "",
            additionalOpen: "",
            reserved: "",
            package: "",
            preSale: false,
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    changeNumberType = value => Number(value)

    onClick = () => {
        const {
            category
        } = this.state;
        if(category.length === 0) {
            toast.error("Enter Required Fields");
        }
        else {
            this.updateTicketInfoMutation();
        }
    }

    onCancelClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId
            }
        })
    }

    onDeleteClick = () => {
        this.deleteTicketInfoMutation();
    }

    render() {
        const {
            ticketInfoId,
            category,
            localAnPrice,
            localFinalPrice,
            localNetPrice,
            InitialOpen,
            additionalOpen,
            reserved,
            preSale
        } = this.state;
        const { history } = this.props;
        // console.log(this.state);
        return (
            <Query
                query={READ_ONE_TICKETINFO}
                variables={{
                    ticketInfoId
                }}
                fetchPolicy={"cache-and-network"}
                onCompleted={this.updateFields}
            >
                {
                    ({loading: queryLoading}) => (
                        <Mutation
                            mutation={UPDATE_TICKET_INFO}
                            variables={{
                                ticketInfoId,
                                category,
                                package: this.state.package,
                                localAnPrice:this.changeNumberType(localAnPrice),
                                localFinalPrice:this.changeNumberType(localFinalPrice),
                                localNetPrice:this.changeNumberType(localNetPrice),
                                InitialOpen:this.changeNumberType(InitialOpen),
                                additionalOpen:this.changeNumberType(additionalOpen),
                                reserved:this.changeNumberType(reserved),
                            }}
                            onCompleted = { data => {
                                const { UpdateTicketInfo } = data;
                                if(UpdateTicketInfo.ok) {
                                    toast.success("Update Ticket Information Completed");
                                    setTimeout(()=>{
                                        history.push({
                                            pathname: "/admin/readconcert",
                                            state: {
                                                concertId: this.state.concertId
                                            }
                                        });
                                    }, 2000);
                                }
                                else {
                                    toast.error(UpdateTicketInfo.error);
                                }
                            }}
                        >
                            {
                                (updateTicketInfoMutation) => {
                                    this.updateTicketInfoMutation = updateTicketInfoMutation;
                                    return (
                                        <Mutation
                                            mutation={DELETE_TICKET_INFO}
                                            variables={{
                                                ticketInfoId
                                            }}
                                            onCompleted = { data => {
                                                const { DeleteTicketInfo } = data;
                                                if(DeleteTicketInfo.ok) {
                                                    toast.success("Delete Ticket Information Completed");
                                                    setTimeout(()=>{
                                                        history.push({
                                                            pathname: "/admin/readconcert",
                                                            state: {
                                                                concertId: this.state.concertId
                                                            }
                                                        });
                                                    }, 2000);
                                                }
                                                else {
                                                    toast.error(DeleteTicketInfo.error);
                                                }
                                            }}
                                        >
                                            {
                                                (deleteTicketInfoMutation) => {
                                                    this.deleteTicketInfoMutation = deleteTicketInfoMutation;
                                                    return (
                                                        <EditTicketPresenter 
                                                            onChange={this.onInputChange}
                                                            onSwitchChange={this.onSwitchChange}
                                                            onClick={this.onClick}
                                                            onCancelClick={this.onCancelClick}
                                                            category={category}
                                                            package={this.state.package}
                                                            onDeleteClick={this.onDeleteClick}
                                                            localAnPrice={localAnPrice}
                                                            localFinalPrice={localFinalPrice}
                                                            localNetPrice={localNetPrice}
                                                            InitialOpen={InitialOpen}
                                                            additionalOpen={additionalOpen}
                                                            reserved={reserved}
                                                            preSale={preSale}
                                                            queryLoading={queryLoading}
                                                        />
                                                    )
                                                }
                                            }
                                        </Mutation>
                                    )
                                }
                            }
                        </Mutation>

                    )
                }
            </Query>
        )
    }

    updateFields = async (data) => {
        if ("ReadOneTicketInfo" in data) {
            const {
                ReadOneTicketInfo: {ticketInfo}
            } = data;
            if (ticketInfo !== null) {
                const {
                    category,
                    localAnPrice,
                    localNetPrice,
                    localFinalPrice,
                    InitialOpen,
                    additionalOpen,
                    reserved,
                    preSale
                } = ticketInfo;
                await this.setState({
                    category,
                    package: ticketInfo.package,
                    localAnPrice,
                    localNetPrice,
                    localFinalPrice,
                    InitialOpen,
                    additionalOpen,
                    reserved,
                    preSale
                });
            }
        }
    }

};

export default EditTicketContainer;