import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForward from "@material-ui/icons/ArrowForward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import searchReportStyle from "assets/jss/material-dashboard-pro-react/views/searchReportStyle";

class SearchReportPresenter extends React.Component {
  render() {
    const { 
      classes,
      // data,
      data: { SearchConcert: { concert =null } = {} } = {},
      keyword,
      onChange,
      concertOnClick
    } = this.props;

    const customButton = (status) => {
      if(status) {
          return (
          <Button
              disabled
              color="facebook"
              className={classes.actionButton}
              size="sm"
          >
              Done
          </Button>
          )  
      }
      else {
          return (
          <Button
              disabled
              color="success"
              className={classes.actionButton}
              size="sm"
          >
              In Process
          </Button>
          )  
      }
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <form>
              <CustomInput
                labelText="Input Search Keyword(Artist, Tour Name, City, Nation)"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  placeholder: "Input Search Keyword(Artist, Tour Name, City, Nation)",
                  type: "text",
                  value: keyword,
                  name: "keyword",
                  onChange,
                }}
              />
            </form>
            </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Sales Report</h4>
              </CardHeader>
              <CardBody>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell align="center">Link</TableCell>
                        <TableCell align="center">Artist</TableCell>
                        <TableCell align="center">Country/City</TableCell>
                        <TableCell align="center">State Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Ticket Capacity</TableCell>
                        <TableCell align="center">Total Sales</TableCell>
                        <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {concert&&concert.map(concert=>(
                        <TableRow key={concert.id}>
                            <TableCell component="th" scope="row" align="center">
                            <Button simple size="sm" color="github" key={concert.id} 
                              onClick={concertOnClick.bind(this,concert.id)}
                            >
                                <ArrowForward />
                            </Button>
                            </TableCell>
                            <TableCell align="center">{concert.tour.artistName}</TableCell>
                            <TableCell align="center">{concert.nationName}/{concert.cityName}</TableCell>
                            <TableCell align="center">{concert.preSaleStartDate}</TableCell>
                            <TableCell align="center">{concert.generalSaleEndDate}</TableCell>
                            <TableCell align="center">{concert.ticketCapacity&&concert.ticketCapacity.toLocaleString()}</TableCell>
                            <TableCell align="center">{concert.totalSales&&concert.totalSales.toLocaleString()}</TableCell>
                            <TableCell align="center">{customButton(concert.reportStatus)}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SearchReportPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(searchReportStyle)(SearchReportPresenter);
