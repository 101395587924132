import { gql } from 'apollo-boost';

export const ADD_TICKET = gql`
    mutation CreateTicketInfo(
        $concertId: Int!
        $category: String
        $package: String
        $localAnPrice: Float
        $localFinalPrice: Float
        $localNetPrice: Float
        $InitialOpen: Int
        $additionalOpen: Int
        $reserved: Int
        $preSale: Boolean
    ) {
        CreateTicketInfo(
            concertId: $concertId
            category: $category,
            package: $package, 
            localAnPrice: $localAnPrice,
            localFinalPrice: $localFinalPrice,
            localNetPrice: $localNetPrice,
            InitialOpen: $InitialOpen,
            additionalOpen: $additionalOpen,
            reserved: $reserved,
            preSale: $preSale
        ) {
            ok
            error
        }
    }
`;