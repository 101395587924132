import React from 'react';
import { Query } from 'react-apollo';
import ReadConcertPresenter from './ReadConcertPresenter';
import { 
    READ_ONE_CONCERT, 
    READ_TICKETINFO, 
    DAILY_SALES_TICKET_LIST,
    TICKET_SALES_DATE,
    DAILY_TOTAL_SALES,
    DAILY_PROMO_SALES
} from './ReadConcertQueries';

class ReadConcertContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            concertId: Number(props.location.state.concertId),
            ticketInfoId: "",
            salesTicketId: "",
        };
    }

    componentDidMount() {
        const refresh = localStorage.getItem("refresh")
        if (refresh) {
            localStorage.removeItem("refresh");    
        }
        else {
            return null;
        }
    }

    editConcertOnClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/editconcert",
            state: {
                concertId
            }
        });
    }

    addTicketOnClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/addticket",
            state: {
                concertId
            }
        });
    }

    editTicketOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            ticketInfoId: key
        })
        history.push({
            pathname: "/admin/editticket",
            state: {
                ticketInfoId: this.state.ticketInfoId,
                concertId: this.state.concertId
            }
        });
    }

    editDailySalesOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            salesTicketId: key
        })
        history.push({
            pathname: "/admin/editdailysales",
            state: {
                salesTicketId: this.state.salesTicketId,
                concertId: this.state.concertId
            }
        });
    }

    render() {
        const { concertId } = this.state;
        return (
            <Query
                query={READ_ONE_CONCERT}
                variables={{
                    concertId
                }}
                fetchPolicy={"cache-and-network"}
            >
                {
                    ({data: readConcertData}) =>  (
                        <Query
                            query={READ_TICKETINFO}
                            variables={{
                                concertId
                            }}
                            fetchPolicy={"cache-and-network"}
                        >
                            {
                                ({data: readTicketInfoData}) => (
                                    <Query
                                        query={DAILY_SALES_TICKET_LIST}
                                        variables={{
                                            concertId
                                        }}
                                        fetchPolicy={"cache-and-network"}
                                    >
                                        {
                                            ({data: readDailySalesData}) => (
                                                <Query
                                                    query={TICKET_SALES_DATE}
                                                    variables={{
                                                        concertId
                                                    }}
                                                    fetchPolicy={"cache-and-network"}
                                                >
                                                    {
                                                        ({data: ticketSalesDateData}) => (
                                                            <Query
                                                                query={DAILY_TOTAL_SALES}
                                                                variables={{
                                                                    concertId
                                                                }}
                                                                fetchPolicy={"cache-and-network"}
                                                            >
                                                                {
                                                                    ({data: dailyTotalSalesData}) => (
                                                                        <Query
                                                                            query={DAILY_PROMO_SALES}
                                                                            variables={{
                                                                                concertId
                                                                            }}
                                                                            fetchPolicy={"cache-and-network"}
                                                                        >
                                                                            {
                                                                                ({data: dailyPromoSalesData}) => (
                                                                                    <ReadConcertPresenter 
                                                                                        editConcertOnClick={this.editConcertOnClick}
                                                                                        addTicketOnClick={this.addTicketOnClick}
                                                                                        editTicketOnClick={this.editTicketOnClick}
                                                                                        editDailySalesOnClick={this.editDailySalesOnClick}
                                                                                        readConcertData={readConcertData}
                                                                                        readTicketInfoData={readTicketInfoData}
                                                                                        readDailySalesData={readDailySalesData}
                                                                                        ticketSalesDateData={ticketSalesDateData}
                                                                                        dailyTotalSalesData={dailyTotalSalesData}
                                                                                        dailyPromoSalesData={dailyPromoSalesData}
                                                                                    /> 
                                                                                )
                                                                            }
                                                                        </Query>
                                                                    )
                                                                }
                                                            </Query>         
                                                        )
                                                    }
                                                </Query>
                                            )
                                        }
                                    </Query>
                                )
                            }
                        </Query>
                    )
                }
            </Query>
            
        )
    }
};

export default ReadConcertContainer;