import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { toast } from 'react-toastify';
import EditDailySalesPresenter from './EditDailySalesPresenter';
import { 
    READ_ONE_SALES_TICKET,
    UPDATE_ONE_SALES_TICKET
} from './EditDailySalesQueries';

class EditDailySalesContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            salesTicketId: Number(props.location.state.salesTicketId),
            concertId: Number(props.location.state.concertId),
            sales: "",
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    changeNumberType = value => Number(value)

    onClick = () => {
        const {
            sales
        } = this.state;
        if(sales.length === 0) {
            toast.error("Enter Required Fields");
        }
        else {
            this.updateDailySalesMutation();
        }
    }

    onCancelClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId
            }
        })
    }

    render() {
        const {
            sales,
            salesTicketId
        } = this.state;
        const { history } = this.props;
        return (
            <Query
                query={READ_ONE_SALES_TICKET}
                variables={{
                    salesTicketId
                }}
                onCompleted={this.updateFields}
            >
                {
                    ({data: dailySalesData,loading: queryLoading}) => (
                        <Mutation
                            mutation={UPDATE_ONE_SALES_TICKET}
                            variables={{
                                salesTicketId,
                                sales: this.changeNumberType(sales) 
                            }}
                            onCompleted = { data => {
                                const { UpdateOneSalesTicket } = data;
                                if(UpdateOneSalesTicket.ok) {
                                    toast.success("Update Daily Sales Ticket Completed");
                                    setTimeout(()=>{
                                        history.push({
                                            pathname: "/admin/readconcert",
                                            state: {
                                                concertId: this.state.concertId
                                            }
                                        });
                                    }, 500);
                                }
                                else {
                                    toast.error(UpdateOneSalesTicket.error);
                                }
                            }}
                        >
                            {
                                (updateDailySalesMutation) => {
                                    this.updateDailySalesMutation = updateDailySalesMutation;
                                    return (
                                        <EditDailySalesPresenter 
                                            onChange={this.onInputChange}
                                            onCancelClick={this.onCancelClick}
                                            onClick={this.onClick}
                                            dailySalesData={dailySalesData}
                                            sales={sales}
                                        />
                                    )
                                }
                            }
                        </Mutation>
                    )
                }
            </Query>
        )
    }

    updateFields = async (data) => {
        if ("ReadOneSalesTicket" in data) {
            const {
                ReadOneSalesTicket: {salesTicket}
            } = data;
            if (salesTicket !== null) {
                const {
                    sales
                } = salesTicket;
                await this.setState({
                    sales
                });
            }
        }
    }
};

export default EditDailySalesContainer;