import React from "react";
import PropTypes from "prop-types";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import addConcertStyle from "assets/jss/material-dashboard-pro-react/views/addConcertStyle";

class AddConcertPresenter extends React.Component {
  render() {
    const { 
      classes,
      onChange,
      onShowDateTimeChange,
      onExchangeRateDateChange,
      onPreSaleStartDateChange,
      onPreSaleEndDateChange,
      onGeneralSaleStartDateChange,
      onGeneralSaleEndDateChange,
      onSwitchChange,
      onClick,
      onCancelClick,
      nationName,
      cityName,
      venue,
      currency,
      exchangeRate,
      preSaleBenefit,
      promotionID,
      bEP,
      salesEstimation,
      isMMT
    } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>
                    Concert Information
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Country
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: nationName,
                          name: "nationName",
                          onChange                        
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        City
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: cityName,
                          name: "cityName",
                          onChange                        
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Venue
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: venue,
                          name: "venue",
                          onChange                        
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Currency
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: currency,
                          name: "currency",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Exchange Rate
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields * (Input Number)"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: exchangeRate,
                          name: "exchangeRate",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Exchange Rate Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          timeFormat={false}
                          utc
                          dateFormat="YYYY-MM-DD"
                          onChange={onExchangeRateDateChange}
                          inputProps={{ placeholder: "Please enter a date *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Show Date Time
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          utc
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          onChange={onShowDateTimeChange}
                          inputProps={{ placeholder: "Please enter a date and time *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Presale Benefit
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Choose Benefit Type (Required Fields *)
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={preSaleBenefit}
                          onChange={onChange}
                          inputProps={{
                            name: "preSaleBenefit",
                            id: "preSaleBenefit"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Choose Benefit Type
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="1"
                          >
                            48h presale 10% discount
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="2"
                          >
                            20% discount
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="3"
                          >
                            Other
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Presale Start Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          timeFormat={false}
                          utc
                          dateFormat="YYYY-MM-DD"
                          onChange={onPreSaleStartDateChange}
                          inputProps={{ placeholder: "Please enter a date *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Presale End Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          timeFormat={false}
                          utc
                          dateFormat="YYYY-MM-DD"
                          onChange={onPreSaleEndDateChange}
                          inputProps={{ placeholder: "Please enter a date *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        General Sale Start Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          timeFormat={false}
                          utc
                          dateFormat="YYYY-MM-DD"
                          onChange={onGeneralSaleStartDateChange}
                          inputProps={{ placeholder: "Please enter a date *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        General Sale End Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Datetime 
                          className={classes.formControl}
                          timeFormat={false}
                          utc
                          dateFormat="YYYY-MM-DD"
                          onChange={onGeneralSaleEndDateChange}
                          inputProps={{ placeholder: "Please enter a date *" }}
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Promotion ID
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: promotionID,
                          name: "promotionID",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        BEP
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: bEP,
                          name: "bEP",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Sales Estimation
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: salesEstimation,
                          name: "salesEstimation",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        MMT host
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <FormControlLabel className={classes.switchLableControl}
                        control={
                          <Switch
                            checked={isMMT}
                            onChange={onSwitchChange('isMMT')}
                            value="isMMT"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.buttondiv}>
                        <Button className={classes.button} round color="facebook" onClick={onClick}>
                          Add
                        </Button>
                        <Button className={classes.button} round color="tumblr" onClick={onCancelClick}>
                          Cancel
                        </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddConcertPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(addConcertStyle)(AddConcertPresenter);
