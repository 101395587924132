import React from "react";
// import PropTypes from "prop-types";

// @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// import ArrowForward from "@material-ui/icons/ArrowForward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Table from "components/Table/Table.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";

// import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class StreamingTestPresenter extends React.Component {
  render() {
    // const { 
    //     classes,
    //     recentTourData: { ReadRecentTour: { tour =null } = {} } = {},
    //     recentConcertData: { ReadRecentConcert: { concert =null } = {} } = {},
    //     tourOnClick,
    //     concertOnClick,
    // } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <h1>MMT Streaming Test</h1>
            <iframe title="MMT Streaming Test" src="//iframe.dacast.com/b/167586/c/543166" width="640" height="360" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// export default withStyles(dashboardStyle)(StreamingTestPresenter);
export default StreamingTestPresenter
