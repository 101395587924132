import React from 'react';
import { Query, Mutation } from 'react-apollo';
import EditTourPresenter from './EditTourPresenter';
import { 
    READ_ONE_TOUR, 
    UPDATE_TOUR,
    DELETE_TOUR
} from './EditTourQueries';
import { toast } from "react-toastify";

class EditTourContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            tourId: Number(props.location.state.tourId),
            tourName: "",
            artistName: "",
            showType: "",
            region: "",            
            projectManager: "",
            tourManager: "",
            MarketingManager: "",
            AMManager: "",
            UCManager: "",
            designer: "",
            VCC: "",
        };
    }

    //Refresh Page
    componentDidMount() {
        const refresh = Number(localStorage.getItem("refresh"))
        if (refresh === 0){
            localStorage.setItem("refresh", 1); 
            window.location.reload();
            return null;
        }
        else {
            return null;
        }
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    onCancelChange = () => {
        const { history } = this.props;
        const { tourId } = this.state;
        history.push({
            pathname: "/admin/readtour",
            state: {
                tourId
            }
        })
    }

    render() {
        const {
            tourId,
            artistName, 
            tourName, 
            showType,
            region, 
            projectManager, 
            tourManager,
            MarketingManager,
            AMManager,
            UCManager,
            designer,
            VCC
        } = this.state;
        const { history } = this.props;
        
        return (
            <Query
                query={READ_ONE_TOUR}
                variables={{
                    tourId
                }}
                fetchPolicy={"cache-and-network"}
                onCompleted={this.updateFields}
            >
                {({loading: queryLoading})=> (
                    <Mutation
                        mutation={UPDATE_TOUR}
                        // refetchQueries={[{ query: READ_ONE_TOUR }]}
                        variables={{
                            tourId, 
                            projectManager, 
                            tourManager,
                            MarketingManager,
                            AMManager,
                            UCManager,
                            designer,
                            artistName,
                            tourName,
                            showType,
                            region,
                            VCC
                        }}
                        onCompleted={ data => {
                            const { UpdateTour } = data;
                            if(UpdateTour.ok) {
                              toast.success("Edit Tour");
                              history.push({
                                pathname: "/admin/readtour",
                                state: {
                                    tourId
                                }
                            });
                            } else if(UpdateTour.error) {
                              toast.error(UpdateTour.error);
                            }
                        }}
                    >
                         {updateTourFn => (
                             <Mutation
                                mutation={DELETE_TOUR}
                                variables={{
                                    tourId
                                }}
                                onCompleted={ data => {
                                    const { DeleteTour } = data;
                                    if(DeleteTour.ok) {
                                      toast.success("Delete Tour");
                                      history.push({
                                        pathname: "/admin/dashboard",
                                    });
                                    } else if(DeleteTour.error) {
                                      toast.error(DeleteTour.error);
                                    }
                                }}      
                             >
                                 {
                                     (deleteTourFn) => (
                                        <EditTourPresenter 
                                            onChange={this.onInputChange}
                                            onEditClick={updateTourFn}
                                            onDeleteClick={deleteTourFn}
                                            onCancelChange={this.onCancelChange}
                                            loading={queryLoading}
                                            // artistId={artistId} 
                                            artistName={artistName} 
                                            tourName={tourName}
                                            region={region}
                                            // regionId={regionId} 
                                            projectManager={projectManager}
                                            tourManager={tourManager}
                                            MarketingManager={MarketingManager}
                                            AMManager={AMManager}
                                            UCManager={UCManager}
                                            designer={designer}
                                            showType={showType}
                                            VCC={VCC}
                                        /> 
                                     )
                                 }
                             </Mutation>
                         )} 
                    </Mutation>
                )}
            </Query>
        )
    }

    updateFields = async (data) => {
        if ("ReadOneTour" in data) {
            const {
                ReadOneTour: {tour}
            } = data;
            if (tour !== null) {
                const {
                artistName, 
                tourName, 
                region, 
                projectManager, 
                tourManager,
                MarketingManager,
                AMManager,
                UCManager,
                designer,
                showType,
                VCC
                } = tour;
                await this.setState({
                artistName, 
                tourName, 
                region, 
                projectManager, 
                tourManager,
                MarketingManager,
                AMManager,
                UCManager,
                designer,
                showType,
                VCC
                });
            }
        }
    }
};

export default EditTourContainer;