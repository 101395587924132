import { gql } from 'apollo-boost';

export const SEARCH_CONCERT = gql`
    query SearchConcert 
    (
        $keyword: String!
    ){
        SearchConcert (
            keyword: $keyword
        )
        {
            ok
            error
            concert {
                id
                tour {
                    artistName
                }
                nationName
                cityName
                preSaleStartDate
                generalSaleEndDate
                ticketCapacity
                totalSales
                reportStatus
            }
        }
    }
`;