import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import editTicketStyle from "assets/jss/material-dashboard-pro-react/views/editTicketStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditTicketPresenter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalStatus: false
    };
  }

  render() {
    const { 
      classes,
      onChange,
      onClick,
      onCancelClick,
      category,
      localAnPrice,
      localFinalPrice,
      localNetPrice,
      InitialOpen,
      additionalOpen,
      reserved,
      preSale,
      onDeleteClick
    } = this.props;

    const { modalStatus } = this.state;

    const modalButton = (modalStatus) => {
        this.setState({
          modalStatus: !modalStatus
        })
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" text>
                <CardText color="warning">
                  <h4 className={classes.cardTitle}>Ticket Information</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {preSale ? 
                  <form>
                 <GridItem xs={12} sm={3}>
                      <GridContainer>
                          <Button 
                              color="rose" 
                              onClick={() => modalButton(modalStatus)} 
                              className="deleteModal"
                          >
                              Delete
                          </Button>
                          <Dialog
                              classes={{
                                  root: classes.center + " " + classes.modalRoot,
                                  paper: classes.modal
                              }}
                              open={modalStatus}
                              TransitionComponent={Transition}
                              keepMounted
                              onClose={() => modalButton(modalStatus)}
                              aria-labelledby="classic-modal-slide-title"
                              aria-describedby="classic-modal-slide-description"
                          >
                              <DialogTitle
                                  id="notice-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                              >
                                <Button
                                  justIcon
                                  className={classes.modalCloseButton}
                                  key="close"
                                  aria-label="Close"
                                  color="transparent"
                                  onClick={() => modalButton(modalStatus)}
                                >
                                    <Close className={classes.modalClose} />
                                </Button>
                                <h4>Delete Ticket</h4>
                              </DialogTitle>
                              <DialogContent
                                  id="small-modal-slide-description"
                                  className={
                                  classes.modalBody + " " + classes.modalSmallBody
                                  }
                              >
                                  <h3 className={classes.modalTitle}>Are you sure delete?</h3>
                              </DialogContent>
                              <DialogActions
                                  className={
                                  classes.modalFooter + " " + classes.modalFooterCenter
                                  }
                              >
                                  <Button
                                      onClick={onDeleteClick}
                                      color="danger"
                                      simple
                                      className={
                                          classes.modalSmallFooterFirstButton +
                                          " " +
                                          classes.modalSmallFooterSecondButton
                                      }
                                  >
                                      Yes
                                  </Button>
                                  <Button
                                      onClick={() => modalButton(modalStatus)}
                                      color="transparent"
                                      className={classes.modalSmallFooterFirstButton}
                                  >
                                      Cancel
                                  </Button>
                              </DialogActions>
                          </Dialog>
                      </GridContainer>
                  </GridItem>   
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Category
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          labelText="Required Fields *"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: category + " (Promocode)",
                            name: "category",
                            onChange,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Package
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "exam) Hi-touch, Meeting",
                            type: "text",
                            value: this.props.package,
                            name: "package",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Announcement Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localAnPrice,
                            name: "localAnPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Final Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localFinalPrice,
                            name: "localFinalPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Net Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localNetPrice,
                            name: "localNetPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.buttondiv}>
                          <Button className={classes.button} round color="warning" onClick={onClick}>
                            Edit
                          </Button>
                          <Button className={classes.button} round color="tumblr" onClick={onCancelClick}>
                            Cancel
                          </Button>
                    </div>
                  </form>
                :  
                  <form>
                      <GridItem xs={12} sm={3}>
                        <GridContainer>
                            <Button 
                                color="rose" 
                                onClick={() => modalButton(modalStatus)} 
                                className="deleteModal"
                            >
                                Delete
                            </Button>
                            <Dialog
                                classes={{
                                    root: classes.center + " " + classes.modalRoot,
                                    paper: classes.modal
                                }}
                                open={modalStatus}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => modalButton(modalStatus)}
                                aria-labelledby="classic-modal-slide-title"
                                aria-describedby="classic-modal-slide-description"
                            >
                                <DialogTitle
                                    id="notice-modal-slide-title"
                                    disableTypography
                                    className={classes.modalHeader}
                                >
                                  <Button
                                    justIcon
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="transparent"
                                    onClick={() => modalButton(modalStatus)}
                                  >
                                      <Close className={classes.modalClose} />
                                  </Button>
                                  <h4>Delete Ticket</h4>
                                </DialogTitle>
                                <DialogContent
                                    id="small-modal-slide-description"
                                    className={
                                    classes.modalBody + " " + classes.modalSmallBody
                                    }
                                >
                                    <h3 className={classes.modalTitle}>Are you sure delete?</h3>
                                </DialogContent>
                                <DialogActions
                                    className={
                                    classes.modalFooter + " " + classes.modalFooterCenter
                                    }
                                >
                                    <Button
                                        onClick={onDeleteClick}
                                        color="danger"
                                        simple
                                        className={
                                            classes.modalSmallFooterFirstButton +
                                            " " +
                                            classes.modalSmallFooterSecondButton
                                        }
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => modalButton(modalStatus)}
                                        color="transparent"
                                        className={classes.modalSmallFooterFirstButton}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </GridContainer>
                    </GridItem> 
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Category
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          labelText="Required Fields *"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: category,
                            name: "category",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Package
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "exam) Hi-touch, Meeting",
                            type: "text",
                            value: this.props.package,
                            name: "package",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Announcement Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localAnPrice,
                            name: "localAnPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Final Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localFinalPrice,
                            name: "localFinalPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Net Price (Local)
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: localNetPrice,
                            name: "localNetPrice",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Initial Open
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: InitialOpen,
                            name: "InitialOpen",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Additional Open
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: additionalOpen,
                            name: "additionalOpen",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Reserved
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: reserved,
                            name: "reserved",
                            onChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.buttondiv}>
                          <Button className={classes.button} round color="warning" onClick={onClick}>
                            Edit
                          </Button>
                          <Button className={classes.button} round color="tumblr" onClick={onCancelClick}>
                            Cancel
                          </Button>
                    </div>
                  </form>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EditTicketPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(editTicketStyle)(EditTicketPresenter);
