import React from 'react';
import DashboardPresenter from './DashboardPresenter';
// import moment from 'moment';
import { Query } from 'react-apollo';
import {
    READ_RECENT_TOUR,
    READ_RECENT_CONCERT
} from './DashboardQueries';

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tourId: "",
            concertId: ""
        };
    }

    tourOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            tourId: key
        });
        history.push({
            pathname: "/admin/readtour",
            state: {
                tourId: this.state.tourId
            }
        });
    }

    concertOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            concertId: key
        });
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId: this.state.concertId
            }
        });
    }

    render() {
        return (
            <Query
                query={READ_RECENT_TOUR}
            >
                {
                    ({data:recentTourData}) => (
                        <Query
                            query={READ_RECENT_CONCERT}
                        >
                            {
                                ({data:recentConcertData}) => (
                                    <DashboardPresenter 
                                        recentTourData={recentTourData}
                                        recentConcertData={recentConcertData}
                                        tourOnClick={this.tourOnClick}
                                        concertOnClick={this.concertOnClick}
                                    />
                                )
                            }
                        </Query>
                    )
                }
            </Query>
        )
    }
}

export default DashboardContainer;