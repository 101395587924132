import PropTypes from 'prop-types';
import React from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import SignIn from '../../layouts/SignIn';
import SignUp from '../../layouts/SignUp';
import Admin from '../../layouts/Admin.jsx';
import { createBrowserHistory } from "history";

const hist = createBrowserHistory();

const AppPresenter = ({ isLoggedIn }) => 
    (
        <Router history={hist}>
            {isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}
        </Router>
        
    );

const LoggedOutRoutes = () => (
    <Switch>
        <Route path={"/"} exact={true} component={SignIn} />
        <Route path="/signup" exact={true} component={SignUp} />
        <Redirect from={"*"} to={"/"} />
    </Switch>
);

const LoggedInRoutes = () => (
    <Switch>
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/admin/dashboard" />
    </Switch>
);

AppPresenter.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

export default AppPresenter;