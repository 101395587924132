import React from 'react';
import { Query } from 'react-apollo';
import ReadTourPresenter from './ReadTourPresenter';
import { READ_ONE_TOUR, READ_CONCERT_LIST } from './ReadTourQueries';

class ReadTourContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            tourId: Number(props.location.state.tourId),
            concertId: "",
            datetime: ""
        };
    }
    componentDidMount() {
        const refresh = localStorage.getItem("refresh")
        if (refresh) {
            localStorage.removeItem("refresh");    
        }
        else {
            return null;
        }
    }

    editOnClick = () => {
            const { history } = this.props;
            const { tourId } = this.state;
            history.push({
                pathname: "/admin/edittour",
                state: {
                    tourId
                }
            });
    }

    addConcertOnClick = () => {
        const { history } = this.props;
        const { tourId } = this.state;
        history.push({
            pathname: "/admin/addconcert",
            state: {
                tourId
            }
        });
    }

    concertOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            concertId: key
        });
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId: this.state.concertId
            }
        });
    }

    render() {
        const { tourId } = this.state;
        return (
            <Query
                query={READ_ONE_TOUR}
                variables={{
                    tourId
                }}
                fetchPolicy={"cache-and-network"}
            >
                {({data: readTourData}) => (
                    <Query
                        query={READ_CONCERT_LIST}
                        variables={{
                            tourId
                        }}
                        fetchPolicy={"cache-and-network"}
                    > 
                        {
                            ({data: readConcertListData}) => {
                                this.readConcertListData = readConcertListData;
                                return(
                                    <ReadTourPresenter 
                                        readTourData={readTourData}
                                        readConcertListData={readConcertListData}
                                        editOnClick={this.editOnClick}
                                        addConcertOnClick={this.addConcertOnClick}
                                        concertOnClick={this.concertOnClick}
                                    />
                                )
                            }
                        }
                    </Query>
                )}
            </Query>
        )
    }
};

export default ReadTourContainer;