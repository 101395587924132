import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import ArrowForward from "@material-ui/icons/ArrowForward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class DashboardPresenter extends React.Component {
  render() {
    const { 
        classes,
        recentTourData: { ReadRecentTour: { tour =null } = {} } = {},
        recentConcertData: { ReadRecentConcert: { concert =null } = {} } = {},
        tourOnClick,
        concertOnClick,
    } = this.props;
    
    const customButton = (status) => {
        if(status) {
            return (
            <Button
                disabled
                color="facebook"
                className={classes.actionButton}
                size="sm"
            >
                Done
            </Button>
            )  
        }
        else {
            return (
            <Button
                disabled
                color="success"
                className={classes.actionButton}
                size="sm"
            >
                In Process
            </Button>
            )  
        }
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Recent Sales Report</h4>
              </CardHeader>
              <CardBody>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell align="center">Link</TableCell>
                        <TableCell align="center">Artist</TableCell>
                        <TableCell align="center">Country/City</TableCell>
                        <TableCell align="center">State Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Ticket Capacity</TableCell>
                        <TableCell align="center">Total Sales</TableCell>
                        <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {concert&&concert.map(concert=>(
                        <TableRow key={concert.id}>
                            <TableCell component="th" scope="row" align="center">
                            <Button simple size="sm" color="github" key={concert.id} onClick={concertOnClick.bind(this,concert.id)}>
                                <ArrowForward />
                            </Button>
                            </TableCell>
                            <TableCell align="center">{concert.tour.artistName}</TableCell>
                            <TableCell align="center">{concert.nationName}/{concert.cityName}</TableCell>
                            <TableCell align="center">{concert.preSaleStartDate}</TableCell>
                            <TableCell align="center">{concert.generalSaleEndDate}</TableCell>
                            <TableCell align="center">{concert.ticketCapacity&&concert.ticketCapacity.toLocaleString()}</TableCell>
                            <TableCell align="center">{concert.totalSales&&concert.totalSales.toLocaleString()}</TableCell>
                            <TableCell align="center">{customButton(concert.reportStatus)}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Recent Tour</h4>
              </CardHeader>
              <CardBody>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell align="center">Link</TableCell>
                        <TableCell align="center">Tour Name</TableCell>
                        <TableCell align="center">Artist</TableCell>
                        <TableCell align="center">Show Type</TableCell>
                        <TableCell align="center">Region</TableCell>
                        <TableCell align="center">Project Manager</TableCell>
                        <TableCell align="center">Tour Manager</TableCell>
                        <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tour&&tour.map(tour=>(
                        <TableRow key={tour.id}>
                            <TableCell component="th" scope="row" align="center">
                            <Button simple size="sm" color="github" key={tour.id} onClick={tourOnClick.bind(this,tour.id)}>
                                <ArrowForward />
                            </Button>
                            </TableCell>
                            <TableCell align="center">{tour.tourName}</TableCell>
                            <TableCell align="center">{tour.artistName}</TableCell>
                            <TableCell align="center">{tour.showType}</TableCell>
                            <TableCell align="center">{tour.region}</TableCell>
                            <TableCell align="center">{tour.projectManager}</TableCell>
                            <TableCell align="center">{tour.tourManager}</TableCell>
                            <TableCell align="center">{customButton(tour.status)}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DashboardPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DashboardPresenter);
