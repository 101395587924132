import React from 'react';
import EditConcertPresenter from './EditConcertPresenter';
import moment from 'moment';
import { Mutation, Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { 
    UPDATE_CONCERT, 
    READ_ONE_CONCERT,
    DELETE_CONCERT
} from './EditConcertQueries';

class EditConcertContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            concertId: Number(props.location.state.concertId),
            nationName: "",
            cityName: "",
            venue: "",
            currency: "",
            exchangeRate: 0,
            exchangeRateDate: "",
            showDate: "",
            preSaleBenefit: "",
            preSaleStartDate: "",
            preSaleEndDate: "",
            generalSaleStartDate: "",
            generalSaleEndDate: "",
            promotionID: "",
            bEP: 0,
            comment: undefined,
            salesEstimation: 0,
            reportStatus: Boolean(0)
        };
    }

    //Refresh Page
    componentDidMount() {
        const refresh = Number(localStorage.getItem("refresh"))
        if (refresh === 0){
            localStorage.setItem("refresh", 1); 
            window.location.reload();
            return null;
        }
        else {
            return null;
        }
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    onExchangeRateDateChange = event => {
        this.setState({
            exchangeRateDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onShowDateTimeChange = event => {
        this.setState({
            showDate: moment(event).format()
        });
    }

    onPreSaleStartDateChange = event => {
        this.setState({
            preSaleStartDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onPreSaleEndDateChange = event => {
        this.setState({
            preSaleEndDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onGeneralSaleStartDateChange = event => {
        this.setState({
            generalSaleStartDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onGeneralSaleEndDateChange = event => {
        this.setState({
            generalSaleEndDate: moment(event).format('YYYY-MM-DD')
        });
    }

    changeNumberType = value => Number(value)

    onClick = () => {
        const {
            venue,
            currency,
            exchangeRateDate,
            preSaleStartDate,
            preSaleEndDate,
            generalSaleStartDate,
            generalSaleEndDate,
            preSaleBenefit,
            promotionID
        } = this.state;
        if( 
            venue.length === 0
            || currency.length === 0
            || exchangeRateDate.length === 0
            || preSaleStartDate.length === 0
            || preSaleEndDate.length === 0
            || generalSaleStartDate.length === 0
            || generalSaleEndDate.length === 0
            || preSaleBenefit.length === 0
            || promotionID.length === 0
        ) {
            toast.error("Enter Required Fields");
        }
        else {
            this.updateConcertMutation();
        }
    }

    onCancelClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId
            }
        })
    }

    onSwitchChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onDeleteClick = () => {
        this.deleteConcertMutation();
    }

    render() {
        const {
            concertId,
            nationName,
            cityName,
            venue,
            currency,
            exchangeRate,
            exchangeRateDate,
            showDate,
            preSaleBenefit,
            preSaleStartDate,
            preSaleEndDate,
            generalSaleStartDate,
            generalSaleEndDate,
            promotionID,
            bEP,
            salesEstimation,
            comment,
            reportStatus
        } = this.state
        const { history } = this.props;
        return (
            <Query
                query={READ_ONE_CONCERT}
                variables={{
                    concertId
                }}
                fetchPolicy={"cache-and-network"}
                onCompleted={this.updateFields}
            >
                {
                    ({loading: queryLoading}) => (
                        <Mutation
                            mutation={UPDATE_CONCERT}
                            variables={{
                                concertId,
                                nationName,
                                cityName,
                                venue,
                                currency,
                                exchangeRate: this.changeNumberType(exchangeRate),
                                exchangeRateDate,
                                showDate,
                                preSaleBenefit: this.changeNumberType(preSaleBenefit),
                                preSaleStartDate,
                                preSaleEndDate,
                                generalSaleStartDate,
                                generalSaleEndDate,
                                promotionID,
                                bEP: this.changeNumberType(bEP),
                                salesEstimation: this.changeNumberType(salesEstimation),
                                comment,
                                reportStatus:(Number(Boolean(reportStatus)))
                            }}
                            onCompleted = { data => {
                                const { UpdateConcert } = data;
                                if(UpdateConcert.ok) {
                                    toast.success("Update Concert Completed");
                                    setTimeout(()=>{
                                        history.push({
                                            pathname: "/admin/readconcert",
                                            state: {
                                                concertId: this.state.concertId
                                            }
                                        });
                                    }, 2000);
                                }
                                else {
                                    toast.error(UpdateConcert.error);
                                }
                            }}
                        >
                            {
                                (updateConcertMutation) => {
                                    this.updateConcertMutation = updateConcertMutation;
                                    return (
                                        <Mutation
                                            mutation={DELETE_CONCERT}
                                            variables={{
                                                concertId
                                            }}
                                            onCompleted = { data => {
                                                const { DeleteConcert } = data;
                                                if(DeleteConcert.ok) {
                                                    toast.success("Delete Concert Completed");
                                                    setTimeout(()=>{
                                                        history.push({
                                                            pathname: "/admin/dashboard"
                                                        });
                                                    }, 2000);
                                                }
                                                else {
                                                    toast.error(DeleteConcert.error);
                                                }
                                            }}
                                        >
                                            {
                                                (deleteConcertMutation) => {
                                                    this.deleteConcertMutation = deleteConcertMutation;
                                                    return (
                                                        <EditConcertPresenter 
                                                            loading={queryLoading}
                                                            onChange={this.onInputChange}
                                                            onExchangeRateDateChange={this.onExchangeRateDateChange}
                                                            onShowDateTimeChange={this.onShowDateTimeChange}
                                                            onPreSaleStartDateChange={this.onPreSaleStartDateChange}
                                                            onPreSaleEndDateChange={this.onPreSaleEndDateChange}
                                                            onGeneralSaleStartDateChange={this.onGeneralSaleStartDateChange}
                                                            onGeneralSaleEndDateChange={this.onGeneralSaleEndDateChange}
                                                            onClick={this.onClick}
                                                            onCancelClick={this.onCancelClick}
                                                            onSwitchChange={this.onSwitchChange}
                                                            onDeleteClick={this.onDeleteClick}
                                                            nationName={nationName}
                                                            cityName={cityName}
                                                            venue={venue}
                                                            currency={currency}
                                                            exchangeRate={exchangeRate}
                                                            exchangeRateDate={exchangeRateDate}
                                                            showDate={showDate}
                                                            preSaleStartDate={preSaleStartDate}
                                                            preSaleEndDate={preSaleEndDate}
                                                            generalSaleStartDate={generalSaleStartDate}
                                                            generalSaleEndDate={generalSaleEndDate}
                                                            preSaleBenefit={preSaleBenefit}
                                                            promotionID={promotionID}
                                                            bEP={bEP}
                                                            salesEstimation={salesEstimation}
                                                            comment={comment}
                                                            reportStatus={reportStatus}
                                                        />
                                                    )
                                                }
                                            }
                                        </Mutation>
                                    )
                                }
                            }
                        </Mutation>
                    )
                }
            </Query>
        )
    }

    updateFields = async (data) => {
        if ("ReadOneConcert" in data) {
            const {
                ReadOneConcert: {concert}
            } = data;
            if (concert !== null) {
                const {
                    nationName,
                    cityName,
                    venue,
                    currency,
                    exchangeRate,
                    exchangeRateDate,
                    showDate,
                    preSaleBenefit,
                    preSaleStartDate,
                    preSaleEndDate,
                    generalSaleStartDate,
                    generalSaleEndDate,
                    promotionID,
                    bEP,
                    salesEstimation,
                    comment,
                    reportStatus
                } = concert;
                await this.setState({
                    nationName,
                    cityName,
                    venue,
                    currency,
                    exchangeRate,
                    exchangeRateDate,
                    showDate: moment(Number(showDate)).utc().format(),
                    preSaleBenefit,
                    preSaleStartDate,
                    preSaleEndDate,
                    generalSaleStartDate,
                    generalSaleEndDate,
                    promotionID,
                    bEP,
                    salesEstimation,
                    comment,
                    reportStatus: Boolean(reportStatus)
                });
            }
        }
    }
};

export default EditConcertContainer;