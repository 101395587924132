import { gql } from 'apollo-boost';

export const UPDATE_CONCERT = gql`
    mutation UpdateConcert(
        $concertId: Int!
        $nationName: String
        $cityName: String
        $venue: String
        $currency: String
        $exchangeRate: Float
        $exchangeRateDate: String
        $showDate: String
        $preSaleBenefit: Int
        $preSaleStartDate: String
        $preSaleEndDate: String
        $generalSaleStartDate: String
        $generalSaleEndDate: String
        $promotionID: String
        $bEP: Int
        $salesEstimation: Int
        $comment: String
        $reportStatus: Int
    ) {
        UpdateConcert(
            concertId: $concertId
            nationName: $nationName
            cityName: $cityName
            venue: $venue
            currency: $currency
            exchangeRate: $exchangeRate
            exchangeRateDate: $exchangeRateDate
            showDate: $showDate
            preSaleBenefit: $preSaleBenefit
            preSaleStartDate: $preSaleStartDate
            preSaleEndDate: $preSaleEndDate
            generalSaleStartDate: $generalSaleStartDate
            generalSaleEndDate: $generalSaleEndDate
            promotionID: $promotionID
            bEP: $bEP
            salesEstimation: $salesEstimation
            comment: $comment
            reportStatus: $reportStatus
        ) {
            ok
            error
        }
    }
`;

export const READ_ONE_CONCERT = gql`
    query ReadOneConcert(
        $concertId: Int!
    ) {
        ReadOneConcert(
            concertId: $concertId
        )
        {
            ok
            error
            concert {
                nationName
                cityName
                venue
                currency
                exchangeRate
                exchangeRateDate
                showDate
                preSaleBenefit
                preSaleStartDate
                preSaleEndDate
                generalSaleStartDate
                generalSaleEndDate
                promotionID
                bEP
                salesEstimation
                reportStatus
                comment
            }
        }
    }
`;

export const DELETE_CONCERT = gql`
    mutation DeleteConcert(
        $concertId: Int!
    ) {
        DeleteConcert(
            concertId: $concertId
        ) {
            ok
            error
        }
    }
`;