import React from 'react';
import AddConcertPresenter from './AddConcertPresenter';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { CreateConcert } from './AddConcertQueries'

class AddCityContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            tourId: Number(props.location.state.tourId),
            nationName: "",
            cityName: "",
            venue: "",
            currency: "",
            exchangeRate: 0,
            exchangeRateDate: "",
            showDate: "",
            preSaleBenefit: "",
            preSaleStartDate: "",
            preSaleEndDate: "",
            generalSaleStartDate: "",
            generalSaleEndDate: "",
            promotionID: "",
            bEP: 0,
            salesEstimation: 0,
            isMMT: true,
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    onShowDateTimeChange = event => {
        this.setState({
            showDate: moment(event).format()
        });
    }

    onExchangeRateDateChange = event => {
        this.setState({
            exchangeRateDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onPreSaleStartDateChange = event => {
        this.setState({
            preSaleStartDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onPreSaleEndDateChange = event => {
        this.setState({
            preSaleEndDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onGeneralSaleStartDateChange = event => {
        this.setState({
            generalSaleStartDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onGeneralSaleEndDateChange = event => {
        this.setState({
            generalSaleEndDate: moment(event).format('YYYY-MM-DD')
        });
    }

    onSwitchChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    changeNumberType = value => Number(value)

    onClick = () => {
        const {
            nationName,
            cityName,
            venue,
            currency,
            exchangeRateDate,
            showDate,
            preSaleStartDate,
            preSaleEndDate,
            generalSaleStartDate,
            generalSaleEndDate,
            preSaleBenefit,
            promotionID
        } = this.state;
        if( 
            nationName.length === 0
            || cityName.length === 0
            || venue.length === 0
            || currency.length === 0
            || exchangeRateDate.length === 0
            || showDate.length === 0
            || preSaleStartDate.length === 0
            || preSaleEndDate.length === 0
            || generalSaleStartDate.length === 0
            || generalSaleEndDate.length === 0
            || preSaleBenefit.length === 0
            || promotionID.length === 0
        ) {
            toast.error("Enter Required Fields");
        }
        else {
            this.createConcertMutation();
        }
    }

    onCancelClick = () => {
        const { history } = this.props;
        const { tourId } = this.state;
        history.push({
            pathname: "/admin/readtour",
            state: {
                tourId
            }
        })
    }

    render() {
        const {
            tourId,
            nationName,
            cityName,
            venue,
            currency,
            exchangeRate,
            exchangeRateDate,
            showDate,
            preSaleStartDate,
            preSaleEndDate,
            generalSaleStartDate,
            generalSaleEndDate,
            preSaleBenefit,
            promotionID,
            bEP,
            salesEstimation,
            isMMT
        } = this.state
        const { history } = this.props
        return (
            <Mutation
                mutation={CreateConcert}
                variables={{
                    tourId,
                    nationName,
                    cityName,
                    venue,
                    currency,
                    exchangeRate: this.changeNumberType(exchangeRate),
                    exchangeRateDate,
                    showDate,
                    preSaleBenefit: this.changeNumberType(preSaleBenefit),
                    preSaleStartDate,
                    preSaleEndDate,
                    generalSaleStartDate,
                    generalSaleEndDate,
                    promotionID,
                    bEP: this.changeNumberType(bEP),
                    salesEstimation: this.changeNumberType(salesEstimation),
                    isMMT,
                }}
                onCompleted = { data => {
                    const { CreateConcert } = data;
                    if(CreateConcert.ok) {
                        toast.success("Create Concert Completed");
                        setTimeout(()=>{
                            history.push({
                                pathname: "/admin/readtour",
                                state: {
                                    tourId: this.state.tourId
                                }
                            });
                        }, 2000);
                    }
                    else {
                        toast.error(CreateConcert.error);
                    }
                }}
            >
                {
                    (createConcertMutation) => {
                        this.createConcertMutation = createConcertMutation;
                        return (
                            <AddConcertPresenter 
                                onChange={this.onInputChange}
                                onShowDateTimeChange={this.onShowDateTimeChange}
                                onExchangeRateDateChange={this.onExchangeRateDateChange}
                                onPreSaleStartDateChange={this.onPreSaleStartDateChange}
                                onPreSaleEndDateChange={this.onPreSaleEndDateChange}
                                onGeneralSaleStartDateChange={this.onGeneralSaleStartDateChange}
                                onGeneralSaleEndDateChange={this.onGeneralSaleEndDateChange}
                                onSwitchChange={this.onSwitchChange}
                                onClick={this.onClick}
                                onCancelClick={this.onCancelClick}
                                nationName={nationName}
                                cityName={cityName}
                                venue={venue}
                                currency={currency}
                                exchangeRate={exchangeRate}
                                preSaleBenefit={preSaleBenefit}
                                promotionID={promotionID}
                                bEP={bEP}
                                salesEstimation={salesEstimation}
                                isMMT={isMMT}
                            />
                        )
                    }
                }
            </Mutation>
        )
    }
};

export default AddCityContainer;