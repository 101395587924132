import { gql } from 'apollo-boost';

export const READ_RECENT_TOUR = gql`
    query ReadRecentTour {
        ReadRecentTour
        {
            ok
            error
            tour {
                id
                tourName
                artistName
                showType
                region
                projectManager
                tourManager
                status
            }
        }
    }
`;

export const READ_RECENT_CONCERT = gql`
    query ReadRecentConcert {
        ReadRecentConcert
        {
            ok
            error
            concert {
                id
                tour {
                    artistName
                }
                nationName
                cityName
                preSaleStartDate
                generalSaleEndDate
                ticketCapacity
                totalSales
                reportStatus
            }
        }
    }
`;