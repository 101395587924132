import React from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { ADD_TICKET } from './AddTicketQueries';
import AddTicketPresenter from './AddTicketPresenter';

class AddTicketContainer extends React.Component {
    constructor(props) {
        super(props);
        if(!props.location.state) {
            props.history.push("/admin");
        }
        this.state = {
            concertId: Number(props.location.state.concertId),
            category: "",
            localAnPrice: "",
            localFinalPrice: "",
            localNetPrice: "",
            InitialOpen: "",
            additionalOpen: "",
            reserved: "",
            preSale: false,
            package: "",
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    onSwitchChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    changeNumberType = value => Number(value)

    onClick = () => {
        const {
            category
        } = this.state;
        if(category.length === 0) {
            toast.error("Enter Required Fields");
        }
        else {
            this.createTicketInfo();
        }
    }

    onCancelClick = () => {
        const { history } = this.props;
        const { concertId } = this.state;
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId
            }
        })
    }

    render() {
        const {
            concertId,
            category,
            localAnPrice,
            localFinalPrice,
            localNetPrice,
            InitialOpen,
            additionalOpen,
            reserved,
            preSale,
        } = this.state;
        const { history } = this.props;
        return (
            <Mutation
                mutation={ADD_TICKET}
                variables={{
                    concertId,
                    category,
                    package: this.state.package,
                    localAnPrice:this.changeNumberType(localAnPrice),
                    localFinalPrice:this.changeNumberType(localFinalPrice),
                    localNetPrice:this.changeNumberType(localNetPrice),
                    InitialOpen:this.changeNumberType(InitialOpen),
                    additionalOpen:this.changeNumberType(additionalOpen),
                    reserved:this.changeNumberType(reserved),
                    preSale,
                }}
                onCompleted = { data => {
                    const { CreateTicketInfo } = data;
                    if(CreateTicketInfo.ok) {
                        toast.success("Create Concert Completed");
                        setTimeout(()=>{
                            history.push({
                                pathname: "/admin/readconcert",
                                state: {
                                    concertId: this.state.concertId
                                }
                            });
                        }, 2000);
                    }
                    else {
                        toast.error(CreateTicketInfo.error);
                    }
                }}
            >
                {
                    (createTicketInfo) => {
                        this.createTicketInfo = createTicketInfo;
                        return (
                            <AddTicketPresenter 
                                onChange={this.onInputChange}
                                onSwitchChange={this.onSwitchChange}
                                onClick={this.onClick}
                                onCancelClick={this.onCancelClick}
                                category={category}
                                package={this.state.package}
                                localAnPrice={localAnPrice}
                                localFinalPrice={localFinalPrice}
                                localNetPrice={localNetPrice}
                                InitialOpen={InitialOpen}
                                additionalOpen={additionalOpen}
                                reserved={reserved}
                                preSale={preSale}
                            />
                        )
                    }
                }
            </Mutation>
        )
    }
};

export default AddTicketContainer;