import React from 'react';
import StreamingTestPresenter from './StreamingTestPresenter';

class StreamingTestContainer extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         tourId: "",
    //         concertId: ""
    //     };
    // }

    render() {
        return (
            <StreamingTestPresenter />
        )
    }
}

export default StreamingTestContainer;