import React from 'react';
import SearchReportPresenter from './SearchReportPresenter';
import { Query } from 'react-apollo';
import { SEARCH_CONCERT } from './SearchReportQueries';

class SearchReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "Keyword"
        };
    }

    onInputChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            [name]: value
        });
    };

    concertOnClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            concertId: key
        });
        history.push({
            pathname: "/admin/readconcert",
            state: {
                concertId: this.state.concertId
            }
        });
    }

    render() {
        const { keyword } = this.state;
        return (
            <Query
                query={SEARCH_CONCERT}
                variables={{
                    keyword
                }}
                // onCompleted={data => console.log(data)}
            >
                {
                    ({data}) => {
                        return (
                            <SearchReportPresenter 
                            data={data}
                            keyword={keyword}
                            onChange={this.onInputChange}
                            concertOnClick={this.concertOnClick}
                        />
                        )
                    }
                }
            </Query>
        )
    }
};

export default SearchReportContainer;