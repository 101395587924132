//CMT Routes
import Dashboard from "views/Dashboard";
import TourList from "views/TourList";
import SearchReport from "views/SearchReport";
import AddTour from "views/AddTour";
import ReadTour from "views/ReadTour";
import EditTour from "views/EditTour";
import AddConcert from "views/AddConcert";
import ReadConcert from "views/ReadConcert";
import EditConcert from "views/EditConcert";
import AddTicket from "views/AddTicket";
import EditTicket from "views/EditTicket";
import EditDailySales from "views/EditDailySales";
import StreamingTest from "views/StreamingTest"

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import Search from "@material-ui/icons/Search";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/tourlist",
    name: "Tour",
    icon: AirplanemodeActive,
    component: TourList,
    layout: "/admin"
  },
  {
    path: "/search",
    name: "Search Report",
    icon: Search,
    component: SearchReport,
    layout: "/admin"
  },
  {
    path: "/streamingTest",
    name: "Streaming Test",
    icon: OndemandVideoIcon,
    component: StreamingTest,
    layout: "/admin"
  },
  {
    path: "/addtour",
    name: "Add Tour",
    component: AddTour,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/readtour",
    name: "Tour Information",
    component: ReadTour,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/edittour",
    name: "Edit Tour",
    component: EditTour,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/addconcert",
    name: "Add Concert",
    component: AddConcert,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/readconcert",
    name: "Concert Information",
    component: ReadConcert,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/editconcert",
    name: "Edit Concert Information",
    component: EditConcert,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/addticket",
    name: "Add Ticket Information",
    component: AddTicket,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/editticket",
    name: "Edit Ticket Information",
    component: EditTicket,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/editdailysales",
    name: "Edit Daily Sales Ticket",
    component: EditDailySales,
    layout: "/admin",
    invisible: true
  },
];
export default dashRoutes;
