import { gql } from 'apollo-boost';

export const CreateTour = gql`
    mutation CreateTour(
        $artistId: Int
        $artistName: String!
        $tourName: String!
        $region: String!
        $showType: String!
        $regionId: Int
        $projectManager: String
        $tourManager: String
        $MarketingManager: String
        $AMManager: String
        $UCManager: String
        $designer: String
        $VCC: String
    ) {
        CreateTour(
            artistId: $artistId
            artistName: $artistName
            tourName: $tourName
            region: $region
            showType: $showType
            regionId: $regionId
            projectManager: $projectManager
            tourManager: $tourManager
            MarketingManager: $MarketingManager
            AMManager: $AMManager
            UCManager: $UCManager
            designer: $designer
            VCC: $VCC
        ) {
            ok
            error
        }
    }
`;