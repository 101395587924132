import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import addTourStyle from "assets/jss/material-dashboard-pro-react/views/addTourStyle";

class AddTourPresenter extends React.Component {
  render() {
    const { 
      classes, 
      onChange,
      onClick,
      // artistId, 
      artistName, 
      tourName, 
      region, 
      // regionId, 
      projectManager, 
      tourManager,
      MarketingManager,
      AMManager,
      UCManager,
      designer,
      showType,
      VCC  
    } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Tour Information</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Tour Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        id="tour-name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: tourName,
                          name: "tourName",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Artist
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: artistName,
                          name: "artistName",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Show Type
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Choose Show Type (Required Fields *)
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={showType}
                          onChange={onChange}
                          inputProps={{
                            name: "showType",
                            id: "showType"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Choose Show Type
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Concert"
                          >
                            Concert
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Fan Meeting"
                          >
                            Fan Meeting
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Other"
                          >
                            Other
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Region
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        id="region"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: region,
                          name: "region",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Project Manager
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        id="projectManager"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: projectManager,
                          name: "projectManager",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Tour Manager
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        id="tourManager"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: tourManager,
                          name: "tourManager",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Marketing Manager
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="MarketingManager"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: MarketingManager,
                          name: "MarketingManager",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Assistant Marketing Manager
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="AMManager"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: AMManager,
                          name: "AMManager",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        User Communication Manager
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="UCManager"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: UCManager,
                          name: "UCManager",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Designer
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="designer"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: designer,
                          name: "designer",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Video Content Creator
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="VCC"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: VCC,
                          name: "VCC",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.buttondiv}>
                        <Button className={classes.button} round color="rose" onClick={onClick}>
                          Add
                        </Button>
                        <Button className={classes.button} round color="tumblr" href="/admin/tourlist">
                          Cancel
                        </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddTourPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(addTourStyle)(AddTourPresenter);
