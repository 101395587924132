import { gql } from 'apollo-boost';

export const CreateConcert = gql`
    mutation CreateConcert(
        $tourId: Int!
        $nationName: String!
        $cityName: String!
        $venue: String!
        $currency: String!
        $exchangeRate: Float!
        $exchangeRateDate: String!
        $showDate: String!
        $preSaleBenefit: Int!
        $preSaleStartDate: String!
        $preSaleEndDate: String!
        $generalSaleStartDate: String!
        $generalSaleEndDate: String!
        $promotionID: String!
        $bEP: Int!
        $salesEstimation: Int!
        $isMMT: Boolean!
    ) {
        CreateConcert(
            tourId: $tourId
            nationName: $nationName
            cityName: $cityName
            venue: $venue
            currency: $currency
            exchangeRate: $exchangeRate
            exchangeRateDate: $exchangeRateDate
            showDate: $showDate
            preSaleBenefit: $preSaleBenefit
            preSaleStartDate: $preSaleStartDate
            preSaleEndDate: $preSaleEndDate
            generalSaleStartDate: $generalSaleStartDate
            generalSaleEndDate: $generalSaleEndDate
            promotionID: $promotionID
            bEP: $bEP
            salesEstimation: $salesEstimation
            isMMT: $isMMT
        ) {
            ok
            error
        }
    }
`;