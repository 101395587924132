import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardText from "components/Card/CardText.jsx";

import addTicketStyle from "assets/jss/material-dashboard-pro-react/views/addTicketStyle";

class AddTicketPresenter extends React.Component {

  render() {
    const { 
      classes,
      onChange,
      onSwitchChange,
      onClick,
      onCancelClick,
      category,
      localAnPrice,
      localFinalPrice,
      localNetPrice,
      InitialOpen,
      additionalOpen,
      reserved,
      preSale,
    } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" text>
                <CardText color="warning">
                  <h4 className={classes.cardTitle}>Ticket Information</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Category
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        labelText="Required Fields *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: category,
                          name: "category",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Package
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "exam) Hi-touch, Meeting",
                          type: "text",
                          value: this.props.package,
                          name: "package",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Announcement Price (Local)
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: localAnPrice,
                          name: "localAnPrice",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Final Price (Local)
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: localFinalPrice,
                          name: "localFinalPrice",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Net Price (Local)
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: localNetPrice,
                          name: "localNetPrice",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Initial Open
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: InitialOpen,
                          name: "InitialOpen",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Additional Open
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: additionalOpen,
                          name: "additionalOpen",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Reserved
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: reserved,
                          name: "reserved",
                          onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Add Promocode?
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <FormControlLabel className={classes.switchLableControl}
                        control={
                          <Switch
                            checked={preSale}
                            onChange={onSwitchChange('preSale')}
                            value="preSale"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.buttondiv}>
                        <Button className={classes.button} round color="warning" onClick={onClick}>
                          Add
                        </Button>
                        <Button className={classes.button} round color="tumblr" onClick={onCancelClick}>
                          Cancel
                        </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddTicketPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(addTicketStyle)(AddTicketPresenter);
