import { gql } from "apollo-boost";

export const READ_USER = gql`
  query readUser {
    ReadUser {
      ok
      error
      cmtUser {
        id
        name
        email
        department
      }
    }
  }
`;