import ApolloClient from 'apollo-boost';

const client = new ApolloClient({
    clientState: {
        defaults: {
            auth: {
                __typename: "Auth",
                isLoggedIn: Boolean(localStorage.getItem("jwt"))
            }
        },
        resolvers: {
            Mutation: {
                logUserIn: (_, { token }, { cache }) => {
                    localStorage.setItem("jwt", token);
                    cache.writeData({
                        data: {
                            auth: {
                                __typename: "Auth",
                                isLoggedIn: true
                            }
                        }
                    });
                    return null;
                },
                logUserOut: (_, __, { cache }) => {
                    localStorage.removeItem("jwt");
                    cache.writeData({
                        data: {
                            auth: {
                                __typename: "Auth",
                                isLoggedIn: false
                            }
                        }
                    });
                    return null;
                }
            }
        }
    },
    request: async (operation) => {
        operation.setContext({
            headers: {
                "CMT-JWT":localStorage.getItem("jwt") || ""
            }
        })
    },
    uri: "https://cmt.mymusictaste.com:4000/graphql"
    // uri: "http://localhost:4000/graphql"
    // uri: "http://54.180.115.184:4000/graphql"
});

export default client;