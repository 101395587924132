import { gql } from 'apollo-boost';

export const READ_TOUR_LIST = gql`
    query ReadTourlist {
        ReadTourlist
        {
            ok
            error
            tour {
                id
                tourName
                artistName
                showType
                region
                projectManager
                tourManager
                status
            }
        }
    }
`;