import { gql } from 'apollo-boost';

export const READ_ONE_TOUR = gql`
    query ReadOneTour(
        $tourId: Int!
    ) {
        ReadOneTour(
            tourId: $tourId
        )
        {
            ok
            error
            tour {
                id
                tourName
                artistName
                showType
                region
                projectManager
                tourManager
                MarketingManager
                AMManager
                UCManager
                designer
                VCC
                status
            }
        }
    }
`;

export const READ_CONCERT_LIST = gql`
    query ReadConcertList(
        $tourId: Int!
    ) {
        ReadConcertList(
            tourId: $tourId
        )
        {
            ok
            error
            concert {
                id
                nationName
                cityName
                showDate
                reportStatus
            }
        }
    }
`;