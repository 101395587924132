import React from 'react';
import { graphql } from 'react-apollo';
import AppPresenter from './AppPresenter';
import { IS_LOGGED_IN } from './AppQueries.local';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const AppContainer = (
    { data }
    ) => (
        <React.Fragment>
            <AppPresenter isLoggedIn={data.auth.isLoggedIn}/>
            <ToastContainer draggable={true} position={"bottom-center"} />
        </React.Fragment>
    );

export default graphql(IS_LOGGED_IN)(AppContainer);



