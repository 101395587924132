import React from 'react';
import { Query } from 'react-apollo';
import TourListPresenter from './TourListPresenter';
import { READ_TOUR_LIST } from './TourListQueries';

class TourListPContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tourId: ""
        };
    }

    onClick = async (key) => {
        const { history } = this.props;
        await this.setState({
            tourId: key
        });
        history.push({
            pathname: "/admin/readtour",
            state: {
                tourId: this.state.tourId
            }
        });
    }

    render() {
        return (
            <Query 
                query={READ_TOUR_LIST} 
            >
                {({data:tourListData}) =>(
                    <TourListPresenter 
                        tourListData={tourListData}
                        onClick={this.onClick}
                    />
                )}
            </Query>
            
        )
    }
};

export default TourListPContainer;