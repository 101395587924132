import { gql } from 'apollo-boost';

export const READ_ONE_TOUR = gql`
    query ReadOneTour(
        $tourId: Int!
    ) {
        ReadOneTour(
            tourId: $tourId
        )
        {
            ok
            error
            tour {
                tourName
                artistName
                showType
                region
                projectManager
                tourManager
                MarketingManager
                AMManager
                UCManager
                designer
                VCC
            }
        }
    }
`;

export const UPDATE_TOUR = gql`
    mutation UpdateTour(
        $tourId: Int!
        $projectManager: String
        $tourManager: String
        $MarketingManager: String
        $AMManager: String
        $UCManager: String
        $designer: String
        $VCC: String
        $artistName: String
        $tourName: String
        $showType: String
        $region: String
    ) {
        UpdateTour(
            tourId: $tourId
            projectManager: $projectManager
            tourManager: $tourManager
            MarketingManager: $MarketingManager
            AMManager: $AMManager
            UCManager: $UCManager
            designer: $designer
            VCC: $VCC
            artistName: $artistName
            tourName: $tourName
            showType: $showType
            region: $region
        )
        {
            ok
            error
        }
    }
`;

export const DELETE_TOUR = gql`
    mutation DeleteTour(
        $tourId: Int!
    ) {
        DeleteTour(
            tourId: $tourId
        ) {
            ok
            error
        }
    }
`;