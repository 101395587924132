import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// moment
import moment from 'moment';

import readTourStyle from "assets/jss/material-dashboard-pro-react/views/readConcertStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ReadConcertPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false    
    };
  }
  render() {
    const { 
      classes,
      readConcertData: { ReadOneConcert: { concert = null } = {} } = {},
      readTicketInfoData: { ReadTicketInfo: { ticketInfo = null } = {} } = {},
      readDailySalesData: { DailySalesTicketList: { dailyTicketInfo = null } = {} }= {},
      ticketSalesDateData: { ReadTicketDate: { result = null } = {} } = {},
      dailyTotalSalesData: { DailyTotalSales: { totalSales = null } = {} } = {},
      dailyPromoSalesData: { DailyPromoSales: { promoSales = null } = {} } = {},
      editConcertOnClick,
      addTicketOnClick,
      editTicketOnClick,
      editDailySalesOnClick,
    } = this.props;

    const{ modalStatus } = this.state;
      
    const processButton = (status) => {
      if(status) {
        return (
          <Button
            disabled
            color="facebook"
            size="sm"
          >
            Done
          </Button>
        )  
      }
      else {
        return (
          <Button
            disabled
            color="success"
            size="sm"
          >
            In Process
          </Button>
        )  
      }
    };

    const modalButton = (modalStatus) => {
      this.setState({
        modalStatus: !modalStatus
      })
    }

    const conversionTimestamp = timestamp => {
      const castingNumber = Number(timestamp)
      return moment(castingNumber).utc().format('YYYY-MM-DD HH:mm')
    }

    const conversionDate = timestamp => {
      const castingNumber = Number(timestamp)
      return moment(castingNumber).utc().format('MM/DD')
    }

    const preSaleBenefitSelector = number => {
        if(number === 1) {
            return "48h presale 10% discount"
        }
        else if(number === 2) {
            return "20% discount"
        }
        else {
            return "other"
        }
    }

    const promoCheck = (preSale, category) => {
      if(preSale) {
        return category + " (Promocode)"
      }
      else {
        return category
      }
    }

    const categoryLength = (category) => {
      return category.substring(0,9)
    }

    return (
      <div>
        <GridContainer className={classes.head}>
          <h2>{concert&&concert.tour&&concert.tour.tourName} ({concert&&concert.nationName} - {concert&&concert.cityName})</h2><br />
        </GridContainer>
        <GridContainer className={classes.head}>
          {concert&&processButton(concert.reportStatus)}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
                <CardBody>
                    <Table>
                      <TableBody>
                          <TableRow>
                              <TableCell align="center">BEP</TableCell>
                              <TableCell align="center">{concert&&concert.bEP.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">Total Revenue</TableCell>
                              <TableCell align="center">{concert&&concert.totalRevenue&&concert.totalRevenue.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">Ticket Capacity</TableCell>
                              <TableCell align="center">{concert&&concert.ticketCapacity&&concert.ticketCapacity.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">Sales Estimation</TableCell>
                              <TableCell align="center">{concert&&concert.salesEstimation.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">Total Sales</TableCell>
                              <TableCell align="center">{concert&&concert.totalSales&&concert.totalSales.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">General Sales</TableCell>
                              <TableCell align="center">{concert&&concert.generalSales&&concert.generalSales.toLocaleString()}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="center">Promo Sales</TableCell>
                              <TableCell align="center">{concert&&concert.promoSales&&concert.promoSales.toLocaleString()}</TableCell>
                          </TableRow>
                          {concert&&concert.comment&&
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                <Button
                                  color="primary"
                                  size="sm"
                                  round
                                  className={classes.marginRight}
                                  onClick={() => modalButton(modalStatus)}
                                >
                                  Comment
                                </Button>
                                <Dialog
                                  classes={{
                                    root: classes.center + " " + classes.modalRoot,
                                    paper: classes.modal
                                  }}
                                  open={modalStatus}
                                  TransitionComponent={Transition}
                                  keepMounted
                                  onClose={() => modalButton(modalStatus)}
                                  aria-labelledby="classic-modal-slide-title"
                                  aria-describedby="classic-modal-slide-description"
                                >
                                  <DialogTitle
                                    id="classic-modal-slide-title"
                                    disableTypography
                                    className={classes.modalHeader}
                                  >
                                    <Button
                                      justIcon
                                      className={classes.modalCloseButton}
                                      key="close"
                                      aria-label="Close"
                                      color="transparent"
                                      onClick={() => modalButton(modalStatus)}
                                    >
                                      <Close className={classes.modalClose} />
                                    </Button>
                                    <h4 className={classes.modalTitle}>Comment</h4>
                                  </DialogTitle>
                                  <DialogContent
                                    id="classic-modal-slide-description"
                                    className={classes.modalBody}
                                  >
                                      {concert&&concert.comment}
                                  </DialogContent>
                                </Dialog>
                              </TableCell>
                            </TableRow>
                          }
                      </TableBody>
                    </Table>
                </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
                <CardHeader color="info" icon>
                    <CardText color="info">
                        <h4 className={classes.cardTitleWhite}>Concert Information</h4>
                    </CardText>
                    <Button 
                        simple 
                        color="linkedin" 
                        className={classes.editButton}
                        onClick={editConcertOnClick}
                    >
                        Edit  
                    </Button>
                </CardHeader>
                <CardBody>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">Venue</TableCell>
                                <TableCell align="center">{concert&&concert.venue}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Currency</TableCell>
                                <TableCell align="center">{concert&&concert.currency}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Exchange Rate</TableCell>
                                <TableCell align="center">{concert&&concert.exchangeRate.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Exchange Rate Date</TableCell>
                                <TableCell align="center">{concert&&concert.exchangeRateDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Show Date</TableCell>
                                <TableCell align="center">{concert&&conversionTimestamp(concert.showDate)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Presale Benefit</TableCell>
                                <TableCell align="center">{concert&&preSaleBenefitSelector(concert.preSaleBenefit)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Presale Date</TableCell>
                                <TableCell align="center">{concert&&concert.preSaleStartDate} ~ {concert&&concert.preSaleEndDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">General Sale Date</TableCell>
                                <TableCell align="center">{concert&&concert.generalSaleStartDate} ~ {concert&&concert.generalSaleEndDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Promotion ID</TableCell>
                                <TableCell align="center">
                                    {concert&& 
                                    <Link
                                      color="inherit"
                                      href={concert.promotionLink}
                                    >
                                      {concert.promotionID}
                                    </Link>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.head}>
          <h2>Ticket Information</h2>
          <Button 
            simple 
            color="rose" 
            className={classes.editButton}
            onClick={addTicketOnClick}
          >
            + Add
          </Button>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.tableCard}>
                <CardBody>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" >Category</TableCell>
                        <TableCell align="center">Package</TableCell>
                        <TableCell align="center">Local Price ({concert&&concert.currency})</TableCell>
                        <TableCell align="center">Price (USD)</TableCell>
                        <TableCell align="center">Capacity</TableCell>
                        <TableCell align="center">Sales</TableCell>
                        <TableCell align="center">Final Sales</TableCell>
                        <TableCell align="center">Remainder</TableCell>
                        <TableCell align="center">Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ticketInfo&&ticketInfo.map(ticketInfo=>{
                        if(ticketInfo.preSale) {
                          return (
                            <TableRow key={ticketInfo.id}>
                              <TableCell align="center" className={classes.tableFontSize}>{promoCheck(ticketInfo.preSale ,ticketInfo.category)}</TableCell>
                              <TableCell align="center" className={classes.tableFontSize}>{ticketInfo.package}</TableCell>
                              <TableCell align="center" className={classes.tableFontSize}>
                                Announcement: {ticketInfo.localAnPrice.toLocaleString()}<br />
                                Final: {ticketInfo.localFinalPrice.toLocaleString()}<br />
                                Net: {ticketInfo.localNetPrice.toLocaleString()}<br />
                              </TableCell>
                              <TableCell align="center" className={classes.tableFontSize}>
                                Announcement: {ticketInfo.usdAnPrice.toLocaleString()}<br />
                                Final: {ticketInfo.usdFinalPrice.toLocaleString()}<br />
                                Net: {ticketInfo.usdNetPrice.toLocaleString()}<br />
                              </TableCell>
                              <TableCell align="center" className={classes.tableFontSize}></TableCell>
                              <TableCell align="center" className={classes.tableFontSize}>
                                {ticketInfo.finalSales.toLocaleString()}
                              </TableCell>
                              <TableCell align="center" className={classes.tableFontSize}></TableCell>
                              <TableCell align="center" className={classes.tableFontSize}></TableCell>
                              <TableCell align="center">
                                <Button color="info" className={classes.actionButton} size="sm" onClick={editTicketOnClick.bind(this, ticketInfo.id)}>
                                  Edit
                                </Button>
                              </TableCell>
                            </TableRow>
                            )
                        }
                          else {
                            return (
                              <TableRow key={ticketInfo.id}>
                                <TableCell align="center" className={classes.tableFontSize}>{promoCheck(ticketInfo.preSale ,ticketInfo.category)}</TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>{ticketInfo.package}</TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>
                                    Announcement: {ticketInfo.localAnPrice.toLocaleString()} <br />
                                    Final: {ticketInfo.localFinalPrice.toLocaleString()} <br />
                                    Net: {ticketInfo.localNetPrice.toLocaleString()} <br />
                                </TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>
                                  Announcement: {ticketInfo.usdAnPrice.toLocaleString()}<br />
                                  Final: {ticketInfo.usdFinalPrice.toLocaleString()}<br />
                                  Net: {ticketInfo.usdNetPrice.toLocaleString()}<br />
                                </TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>
                                  Total: {ticketInfo.totalCapacity.toLocaleString()} <br />
                                  Initial Open: {ticketInfo.InitialOpen.toLocaleString()} <br />
                                  Additional Open: {ticketInfo.additionalOpen.toLocaleString()} <br />
                                  Reserved: {ticketInfo.reserved.toLocaleString()} <br />
                                  Net Capacity: {ticketInfo.netCapacity.toLocaleString()} <br />
                                </TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>{ticketInfo.genSales.toLocaleString()}</TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>{ticketInfo.finalSales.toLocaleString()}</TableCell>
                                <TableCell align="center" className={classes.tableFontSize}>{ticketInfo.remainder.toLocaleString()}</TableCell>
                                <TableCell align="center">
                                  <Button color="info" className={classes.actionButton} size="sm" key={ticketInfo.id} onClick={editTicketOnClick.bind(this, ticketInfo.id)}>
                                    Edit
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          }
                        }
                      )}
                    </TableBody>
                  </Table>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.head}>
          <h2>Daily Sales</h2>
        </GridContainer>
        <GridContainer>
          <GridItem xs={3} sm={3} md={3}>
            <Card className={classes.tableCard}>
                <CardBody>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          Category
                        </TableCell>
                        <TableCell align="center">
                          Sales
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dailyTicketInfo&&dailyTicketInfo.map(dailyTicketInfo=>(
                        <TableRow key={dailyTicketInfo.id}>
                          {dailyTicketInfo.category.length < 10 ?
                            <TableCell align="center">
                               {dailyTicketInfo.category}{dailyTicketInfo.preSale ? " (P)" : null}
                            </TableCell> : 
                              <TableCell align="center">
                                {categoryLength(dailyTicketInfo.category)}...{dailyTicketInfo.preSale ? " (P)" : null}
                              </TableCell>    
                          }   
                            {dailyTicketInfo.preSale ?
                              <TableCell align="center">
                                {dailyTicketInfo.finalSales} 
                              </TableCell>   
                              : 
                              <TableCell align="center">
                                {dailyTicketInfo.genSales}
                              </TableCell>
                            }
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell align="center">
                          Total
                        </TableCell>
                        <TableCell align="center">
                          {concert&&concert.generalSales&&concert.totalSales.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          Promo Total
                        </TableCell>
                        <TableCell align="center">
                          {concert&&concert.promoSales&&concert.promoSales.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            <Card className={classes.tableCard}>
                <CardBody>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {result&&result.map(result=>(
                          <TableCell align="center" key={result.salesDate}>
                            {conversionDate(result.salesDate)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dailyTicketInfo&&dailyTicketInfo.map(dailyTicketInfo=>(
                        <TableRow key={dailyTicketInfo.id}>
                          {
                            dailyTicketInfo.dailySalesTicket&&dailyTicketInfo.dailySalesTicket.map(dailySalesTicket=>{
                              return (
                                <TableCell key={dailySalesTicket.id} align="center">
                                  <Link
                                    key={dailySalesTicket.id}
                                    color="inherit"
                                    component="button"
                                    onClick={
                                      editDailySalesOnClick.bind(this, dailySalesTicket.id)
                                    }
                                  >
                                    {dailySalesTicket.sales}
                                  </Link>
                                </TableCell>
                              )
                            })                            
                          }
                        </TableRow>
                      ))}
                      <TableRow>
                        {
                          totalSales&&totalSales.map(totalSales =>(
                              <TableCell align="center" key={totalSales.salesDate}>
                                {totalSales.sales}
                              </TableCell>  
                            ))
                        }
                      </TableRow>
                      <TableRow>
                        {
                          promoSales&&promoSales.map(promoSales =>(
                              <TableCell align="center" key={promoSales.salesDate}>
                                {promoSales.sales}
                              </TableCell>  
                            ))
                        }
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
        </GridContainer>
      </div>
    );
  }
}

ReadConcertPresenter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(readTourStyle)(ReadConcertPresenter);
